import React from 'react'

export default class FAQ extends React.Component {
  render() {
    return (
      <div className="faq">
        {this.props.children}  
      </div>
    )
  }
} 