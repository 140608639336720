import React from "react"

import FAQ from '../components/faq.js'
import FAQItem from '../components/faq-item.js'
import MarketingLayout from '../layouts/marketing.js'
import Styles from "../styles/status.module.scss";
import Icon from "../components/icon.js"

export default class Status extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      statusRefs: [],
      shownStatuses: []
    }

    this.grabStatusRef = ele => {
      let refs = this.state.statusRefs
      refs.push(ele)
      this.setState({
        statusRefs: refs
      })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll.bind(this));
  }

  onScroll(e) {
    const scrollThreshold = window.innerHeight + window.scrollY
    let refs = this.state.statusRefs
    let showStatuses = []
    refs.forEach((ele, index) => {
      if (ele.offsetTop < scrollThreshold) {
        refs.splice(index, 1)
        showStatuses.push(ele.getAttribute('id'))
      }
    })

    if (showStatuses.length) {
      this.setState({
        statusRefs: refs,
        shownStatuses: this.state.shownStatuses.concat(showStatuses)
      })
    }
  }

  render() {
    return (
      <MarketingLayout title="Carrick Status"
                      description="Get Status Page information directly within Help Scout. Carrick Status shows you the latest info from your status page so that you can build Trust with your customers."
                      shortDescription="See live incident status directly within Help Scout">
        <div className="wrapper">
          <h1>
            <img src="/img/logo.svg" alt="Carrick Status" />
            Carrick <span className="bold-hd">Status</span>
          </h1>

          <div className="tagAndScreen">
            <h2>See <em>live</em> incident status directly within Help Scout</h2>        
            <img src="/img/status-screenshot.png" alt="Carrick Status directly within Help Scout interface" />
          </div>
          <div className="cta-wrapper">
            <a className="btn cta" target="_blank" rel="noreferrer" href="https://carrick-processor.herokuapp.com/signup/status">Install Now</a>
          </div>
        </div>

        <div className={Styles.statusExamples}>
          <div className={Styles.statusExample}>
            <h3>Give your Support Team confidence when</h3>
            <div className={`${Styles.statusIndicator} ${Styles.statusGreen}`}>
              <span>All Systems Operational</span>
            </div>
          </div>

          <div className={`${Styles.statusExample} ${this.state.shownStatuses.indexOf('investigating') >= 0 ? '' : Styles.hide}`}
               id="investigating"
               ref={this.grabStatusRef} >
            <h3>Then let the team know when</h3>
            <div className={`${Styles.statusIndicator} ${Styles.statusBlack}`}>
              <span>Investigating Incident</span>
            </div>
          </div>

          <div className={`${Styles.statusExample} ${this.state.shownStatuses.indexOf('degraded') >= 0 ? '' : Styles.hide}`}
               id="degraded"
               ref={this.grabStatusRef} >
            <h3>Help your team communicate trust when</h3>
            <div className={`${Styles.statusIndicator} ${Styles.statusOrange}`}>
              <span>Partially Degraded Service</span>
              <div className={Styles.components}>
                <h4>
                  <Icon name="exclamation-triangle" />
                    Platform API
                </h4>
                <span>Degraded Performance</span>
              </div>
            </div>
          </div>

          <div className={`${Styles.statusExample} ${this.state.shownStatuses.indexOf('outage') >= 0 ? '' : Styles.hide}`}
               id="outage"
               ref={this.grabStatusRef} >
            <h3>Align on incident response when</h3>
            <div className={`${Styles.statusIndicator} ${Styles.statusRed}`}>
              <span>Partial System Outage </span>
              <div className={Styles.components}>
                <div>
                  <h4>
                    <Icon name="exclamation-triangle" />
                    Platform API
                  </h4>
                  <span>Major Outage</span>
                </div>
                <div>
                  <h4>
                    <Icon name="exclamation-triangle" />
                    Website
                  </h4>
                  <span>Major Outage</span>
                </div>
                <div>
                  <h4>
                    <Icon name="exclamation-triangle" />
                    Dashboard
                  </h4>
                  <span>Major Outage</span>
                </div>
              </div>
            </div>
          </div>

          <div className={`${Styles.lastExample} ${Styles.statusExample} ${this.state.shownStatuses.indexOf('resolved') >= 0 ? '' : Styles.hide}`}
               id="resolved"
               ref={this.grabStatusRef} >
            <h3>Finally, take a deep breath, and lead your Support team with confidence</h3>
            <div className={`${Styles.statusIndicator} ${Styles.statusGreen}`}>
              <span>All Systems Operational</span>
            </div>
          </div>

          <div className="cta-wrapper">
            <a className="btn cta" target="_blank" rel="noreferrer" href="https://carrick-processor.herokuapp.com/signup/status">Install Now</a>
          </div>
        </div>

        <FAQ>
          <FAQItem question="What status pages do you support?"
                  answer="Currently we support StatusPage Issues. If you use another status page, or something custom, reach out and we'll help with an integration!" />

          <FAQItem question="Will this work with all of my status components?"
                    answer="Totally - we automatically read in all of your components and report on the status for each. No configuration required!" />
        </FAQ>
      </MarketingLayout>
    )
  }
}
