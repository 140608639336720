import React from 'react'

export default class CustomQuote extends React.Component {
  render() {
    return (
      <div className="custom-quote wrapper">
        <div>
          <h3>Need something different?</h3>
          <p>With years of development experience and intimate knowledge of the Help Scout platform, Carrick is the perfect partner for your custom Help Scout app.</p>
        </div>
        <form method="POST" data-netlify="true" netlify-honeypot="i-am">
          <p className="who-are-you">
            <label>Don’t fill this out if you're human: <input name="i-am" /></label>
          </p>
          <label htmlFor="email">
            Email Address
            <input name="email"type="email" placeholder="you@supercoolcompany.com" />
          </label>
          <label htmlFor="message">
            Message
            <textarea name="message" placeholder="Tell us a bit about your business"></textarea>
          </label>
          <input type="submit" value="Contact Us" />
        </form>
      </div>
    )
  }
}