import React from 'react'

export default class FAQItem extends React.Component {
  render() {
    return (
      <div className="question">
        <h4>{this.props.question}</h4>
        <p>{this.props.answer}</p>
      </div>
    )
  }
}