import React from 'react'

export default class Icon extends React.Component {
  render() {
    let inner = {
      __html: `<use href='/img/bootstrap-icons.svg#${this.props.name}' />`
    }
    return (
      <svg dangerouslySetInnerHTML={inner} />
    )
  }
}